<template>
  <div>
    <form  @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="offset-1 col-sm-8 shadow rounded-lg p-4">
          <!-- INPUT -->
          <div class="sm-form">
            <input
              type="text"
              id="id"
              name="id"
              class="form-control form-control-sm"
              placeholder="id"
              v-model="vdata.id"
              disabled
            />
          </div>
          <table class="table table-sm table-responsive">
          <tr>        
             <td class="capitalize">tanggal</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input required type="datetime-local" id="tanggal" name="tanggal" class="form-control form-control-sm" placeholder="tanggal" v-model="vdata.tanggal" >
                 </div>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">barcode</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <v-select :options="produks" label="item_data" v-model="vdata.id_produk" @input="cekstok" :reduce="e => e.id"></v-select>
                <!-- <select required class='form-control' v-model="vdata.id_produk" @change="cekstok">
                <option v-for="(item, index) in produks" :key="index+'pridils'" :value="item.id">{{item.barcode}}/{{item.nama_produk}}</option>
                </select> -->
             </td>
         </tr>
          <tr>        
             <td class="capitalize">Jumlah</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                 <div class="sm-form ">
                     <input required type="number" :disabled='stok==0' min="1" :max="stok" id="jumlah" name="jumlah" class="form-control form-control-sm" placeholder="jumlah" v-model="vdata.jumlah" >
                 </div>
                 <p class="text-xs text-red-300">Stok sisa : {{stok}}</p>
             </td>
         </tr>
          <tr>        
             <td class="capitalize">Pasien</td>
             <td class="pl-2 pr-2">:</td>
             <td>
                <select class='form-control' v-model="vdata.id_pelanggan">
                <option v-for="(item, index) in pelanggans" :key="index+'pridils'" :value="item.id">{{item.nama}}</option>
                </select>
             </td>
         </tr>
         </table>
          <!-- END INPUT -->
          <!-- BUTTON PART -->
          <div class="mt-3 p-3">
            <button
              type="submit"
              class="btn btn-sm"
              :class="
                btn == 'tambah'
                  ? 'btn-primary'
                  : btn == 'update'
                  ? 'btn-success'
                  : 'btn-danger'
              "
            >
              <span class="typcn typcn-info"></span>  Proses {{ btn }}
            </button>
            <div class="float-right">
              <button
                type="button"
                v-show="btn!='tambah'"
                @click="
                  btn = 'tambah';
                  vdata = {};
                "
                class="btn btn-sm btn-dark"
              >
                <span class="typcn typcn-edit"></span> Tambah
              </button>
              <button
                type="button"
                @click="btn = 'delete'"
                v-show="btn!='tambah'"
                class="ml-3 btn btn-sm btn-danger"
              >
                <span class="typcn typcn-delete"></span> Delete
              </button>
            </div>
          </div>
          <!-- END BUTTON PART -->
            <div v-if="datanya.length>0" class="p-3">
                <Btables :tables="datanya"  :cari="['']" :hide="['id_produk','kategori','satuan','stok','keluar']" @selected="ambil" ref="btable" />
            </div>
        </div>
        <div class="col-sm-2 shadow rounded-lg p-3">
            <div class="sm-form ">
                <label for="nota">Nota</label>
                <input type="text" disabled id="nota" name="nota" class="form-control form-control-sm" placeholder="nota" v-model="vdata.nota" >
            </div>
            <hr>
            <div class="sm-form ">
                <label for="uang">Uang : </label>
                <input type="number" id="uang" name="uang" class="form-control form-control-sm" placeholder="uang" v-model="uang" >
            </div>
            <hr>
            <p class="">total Rp. {{total}}</p>
            <p>Uang : {{uang}}</p>
            <button type="button" @click="diskon" :disabled="diskons" class="btn btn-sm btn-primary  ">Apply Diskon</button>
            <p v-show="uang-total">Kembalian : {{uang-total}}</p>
            <hr>
            <button type="button" @click="bayar" class="btn btn-sm btn-dark  ">Bayar</button>
        </div>
      </div>
    </form>
    <!-- TABLE PART -->
    <!-- END TABLE PART -->
     <div class="d-block d-sm-none"><br><br><br><br></div>
  </div>
</template>

<script>
// TOTURIAL 
// ganti source autophp, ganti source component btable, ganti path table sdb
import autophp from "@/plugins/autophp_kasir.js"; // ganti source nya
import Btables from "@/components/Btables.vue"; //component btable nya
let sdb = new autophp();
import _ from 'lodash'
export default {
layout:'app_shop_admin',
  components: {
    Btables,
  },
  data() {
    return {
      selected: false,
      vdata: {},
      produks:[],
      pelanggans:[],
      btn: "tambah",
      stok:0,
      datanya:[],
      total:0,
      uang:0,
      diskons:false
    };
  },
  methods: {
      test(){
          alert('y')
      },
      format(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD");
      },
      format2(tanggal) {
        return this.$datefns.format(new Date(tanggal),"YYYY-MM-DD-HH-mm");
      },
      getNota(){
          let random = _.random(0, 24);
          let code=['AA','BB','CC','DD','EE','FF','GG','HH','II','JJ','KK','LL','MM',"NN",'OO','PP','QQ','RR','SS','TT','UU','VV','XX','YY','ZZ']
          sdb.collection("app_kasir_transaksi_nota").doc().get("*","order by id").then(res => {
              this.vdata.nota=code[random]+this.format2(new Date()).split('-').join('')+'/'+res.length;
              this.$forceUpdate();
          });
      },
     async cekstok(item){
        console.log(this.vdata)
      let stok=await sdb.collection("app_kasir_produk").doc().get("*",`where id='${this.vdata.id_produk}' order by id`).then(res => {
          this.vdata.diskon=res[0].diskon
        return parseInt(res[0].stok)
      });
      let masuk = await sdb.collection("app_kasir_stok_masuk").doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      let keluar = await sdb.collection("app_kasir_stok_keluar").doc().get("*",`where id_produk='${this.vdata.id_produk}' order by id`).then(res => {
        return res.reduce((e,n)=>{
          return e+parseInt(n.jumlah)
        },0)
      });
      
      let transaksi=await sdb.collection("app_kasir_transaksi_nota",false).doc().select(`SELECT * FROM app_kasir_transaksi_nota tb1 left join app_kasir_keranjang tb2 on tb1.nota=tb2.nota where tb2.id_produk='${this.vdata.id_produk}' order by tb1.id`).then(res => {
          console.log('res',res);
           return res.reduce((e,n)=>{
            return e+parseInt(n.jumlah)
            },0)
      });
  
      stok=stok+(masuk-keluar-transaksi)
      this.stok=stok;
      this.$forceUpdate();
    },
    diskon(){
        let data= this.datanya;
        if(data.length>0){
            this.total=data.reduce((e,n)=>{
                let diskon = (parseInt(n.diskon)/100)*parseInt(n.harga)
                return e+((parseInt(n.harga)*parseInt(n.jumlah))-diskon)
            },0)
            this.diskons=true;
        }
        this.$forceUpdate();
    },
    getData(){
      sdb.collection("app_kasir_keranjang",false).doc().select(`select tb1.id,tb1.nota,tb1.tanggal,tb1.id_pelanggan,tb2.barcode,tb2.harga,tb1.jumlah,tb2.nama_produk,tb1.id_produk,tb2.diskon from app_kasir_keranjang tb1 left join app_kasir_produk tb2 on tb2.id=tb1.id_produk where tb1.nota='${this.vdata.nota}'`).then(res => {
        this.datanya=res;
        console.log(res)
        if(res.length>0){
            this.total=res.reduce((e,n)=>{
                return e+(parseInt(n.harga)*parseInt(n.jumlah))
            },0)
        }
        this.$forceUpdate();
      });
      sdb.collection("app_kasir_produk").doc().get("*","where type='1' order by id").then(res => {
          this.produks=res;
          this.produks.map(function (x){ // taruh setelah produks di initial
                return x.item_data = x.barcode + ' / ' + x.nama_produk;
          });
      });
       sdb.collection("app_kasir_pelanggan").doc().get("*","order by id").then(res => {
          this.pelanggans=res;
      });
      
    },
    ambil(data) {
      this.vdata = data;
      this.cekstok();
      console.log(data)
      this.btn = "update";
      this.$forceUpdate();
    },
    bayar(){
        if(confirm('Apakah yakin di proses ?')){
            this.vdata.total=this.total;
            this.vdata.uang=this.uang
            let data = this.datanya;
            data=data.map(e=>{
                return e.id
            })
            delete this.vdata.id_produk
            delete this.vdata.status
            this.vdata.list_id=JSON.stringify(data)
            sdb.collection('app_kasir_transaksi_nota').doc().set(this.vdata).then(res=>{
                console.log(res)
                this.$router.go()
            })
        }
    },
    submit() {
      let that=this
      this.vdata.status='keluar'
      this.vdata.tanggal_w=this.format(new Date(this.vdata.tanggal));
      if (confirm("Apakah yakin proses dilanjutkan ?")) {
        if (this.btn == "tambah") {
          //idnya letak di vdata
          delete this.vdata.id;
          sdb
            .collection("app_kasir_keranjang")
            .doc()
            .set(this.vdata)
            .then((res) => {
              that.getData();
              that.cekstok();
              
            // that.$nuxt.$emit('getData')

            });
        } else if (this.btn == "update") {
          sdb
            .collection("app_kasir_keranjang")
            .doc()
            .set(this.vdata)
            .then((res) => {
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
            });
        } else if (this.btn == "delete") {
          sdb
            .collection("app_kasir_keranjang")
            .doc(this.vdata.id)
            .delete()
            .then((res) => {
              that.getData();
              that.cekstok();
            // that.$nuxt.$emit('getData')
            });
        }
        // this.$nuxt.$emit('getData','ok')
      }
    },
  },
  async mounted() {
    this.getNota();
    this.getData();
    this.vdata.tanggal=this.$datefns.format(new Date(),"YYYY-MM-DDTHH:mm")
  },
};
</script>